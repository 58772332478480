.loadingContainer {
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.mobileLeftAlignStyle {
  font-family: Arial;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 60px;
  margin-left: 40px;
}

.topLine {
  border-top: 1px solid #d1cece;
  width: 90%;
  margin-bottom: 1em;
  margin-left: 1px;
}

.healthTable {
  width: 300px;
  border-collapse: collapse;
  font-size: 14px;
}

.headerCell {
  padding: 12px;
  border-bottom: 1px solid #ddd;
  text-align: left;
}

.row {
  background-color: #ffffff;
}

.alternateRow {
  background-color: #f0f0f0;
}

.cell {
  text-align: left;
  padding: 12px;
  border-bottom: 1px solid #ddd;
}

.serverStatus {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.statusDot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.green {
  background-color: green;
}

.red {
  background-color: red;
}

.gray {
  background-color: gray;
}

.statusText {
  display: inline;
  font-size: 14px;
}
