@import "../../variables.scss";

.error-banner {
  width: 100%;
  height: 100px;
}

.error-notification {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 7%;
  margin-right: 20%;
}

.error-icon {
  margin-right: 20px;
  margin-top: 4px;
  color: $jnj-red;
  align-self: start;
}

.error-message {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}

.error-code-details {
  color: $grey;
}

.error-maintext {
  margin-bottom: 5px;
  font-size: 1.7rem;
}

.error-subtext {
  font-weight: normal;
  margin-bottom: 8px;
}

.sign-in-again > a {
  color: $jnj-red;
  font-weight: normal;
}

.hidden {
  display: none;
}
