@import "../../variables.scss";

.modalContainer {
  position: fixed;
  top: 15%;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: white;
  padding: 1.3rem;
  border-radius: 8px;
  outline: none;
  max-width: 500px;
  width: 90%;
  max-height: 90%;
  overflow-y: auto;
  box-sizing: border-box;
}

.mobileModal {
  top: 5%;
  width: 85%;
  max-width: 400px;
  max-height: 80%;
}

.closeButton {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  font-size: 0.1rem;
}

.closeIcon {
  color: rgb(140, 135, 132);
}

.title {
  margin-bottom: 1rem;
  font-family: 'JohnsonDisplay', Arial;
  font-style: normal;
  font-weight: 1000;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
}

.prompt {
  font-family: 'JohnsonTextMedium', Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  color: rgb(140, 135, 132);
}

.placeholder {
  margin-bottom: 2rem;
  color: #888;
  font-family: 'JohnsonText', Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
}

.buttonContainerDesktop {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 0;
}

.muteText {
  position: relative;
  top: 30px;
  right: 4px;
  font-size: 12px;
  color: #000000;
  cursor: pointer;
  font-family: 'JohnsonText', Arial;

  @media (max-width: 834px) {
    transform: translate(15%);
    position: relative;
    top: 110px;
    left: 14px;
    font-size: 12.5px;
  }
}

.thankYouText {
  font-family: 'JohnsonDisplay', Arial;
  top: 15%;
  left: 50%;
  background-color: white;
  padding: 1.3rem;
  border-radius: 8px;
  outline: none;
  max-width: 500px;
  width: 90%;
  text-align: left;
}

.thankYouMessage {
  font-family: 'JohnsonText', Arial;
  font-size: 16px;
  padding-bottom: 35px;
}


.closeButtonRight {
  position: absolute;
  top: 210px;
  right: 20px;

  &.closeButtonMobile {
    top: auto;
    bottom: 20px;
    right: 25%;
    transform: translateX(50%);
  }
}


.buttonContainerMobile {
  width: 100%;
  justify-content: center;
  gap: 5px;
  margin-bottom: 30px;
}
