@import 'src/variables.scss';

.dashboard-page {
  text-align: left;
  position: relative;
}

.welcome-header {
  margin-left: 48px;
}

.welcome-header-mobile {
  margin-left: 9px;
}

.profile-picture {
  float: left;
  margin-right: 40px;
}

.mobile-profile-picture {
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2000;

  width: 7rem;
  height: 7rem;
}

.full-name-header {
  margin: auto;
  padding-left: 250px;
}

.mobile-full-name-header {
  text-align: center;
}

.user-info {
  margin: auto;
}

.my-apps-header {
  margin-top: 7.5%;
  margin-bottom: 2.5%;
}

.app-grid {
  margin-bottom: 5%;
  margin-right: 2.5%;
  width: 95%;
  height: 100%;
  padding: 1% 2% 5rem;
}

.jnj-footer {
  float: center;
}

.middle-section {
  //display: grid;
  background-color: #F4F3F1;
  width: 100vw;
  min-height: 60vh;
  // margin: 0;
} 

.middle-section-no-apps {
  margin-top: 2%;
  margin-left: 48px;
  width: 100vw;
  min-height: 56vh;
} 

.middle-section-no-apps-mobile {
  margin-top: 2%;
  margin-left: 9px;
  width: 100vw;
  min-height: 56vh;
}