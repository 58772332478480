@import 'src/variables.scss';
@import 'src/App.scss';

.text-textbox, .password-textbox, .textbox-label, .textbox-support-text {
    font-family: 'JohnsonText', Arial, sans-serif;
}

.text-textbox, .password-textbox {
    width: 23rem;
}

.text-textbox, .password-textbox, .textbox-label {
    font-size: 0.9rem;
}

.textbox-support-text {
    font-size: 0.65rem;
}

.desktop .text-textbox,
.desktop .password-textbox {
    width: 100%;
    min-width: 400px;
}

.mobile .text-textbox,
.mobile .password-textbox {
    width: 100%;
    min-width: 350px;
}
