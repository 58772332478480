@import 'src/variables.scss';

.footer-wrapper-small {
  overflow: hidden;
  max-width: 640px;
  display: flex;
  justify-content: center;
  padding: 10px 20px;
  vertical-align: middle;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  margin: 0 auto;
  padding-top: 40px;
  bottom: 0;
}

.footer-wrapper-medium {
  overflow: hidden;
  max-width: 1080px;
  display: flex;
  justify-content: center;
  padding: 15px 30px;
  vertical-align: middle;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  margin: 0 auto;
  padding-top: 90px;
  bottom: 0;
}

.footer-wrapper-large {
  overflow: hidden;
  max-width: 100rem;
  display: flex;
  justify-content: center;
  padding: 20px 40px;
  vertical-align: middle;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  margin: 0 auto;
  padding-top: 90px;
  bottom: 0;
}

.footer-text-small {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-top: 65px;
  font-family: 'JohnsonDisplay', Arial;
  margin-bottom: 0;
}

.footer-text-medium {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  font-family: 'JohnsonDisplay', Arial;
  margin-bottom: 0;
}

.footer-text-large {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  font-family: 'JohnsonDisplay', Arial;
  margin-bottom: 0;
}

.footer-image {
  height: 30px;
  margin: auto 0px;
  display: block;
}

@media (max-width: 413px) {
    .footer-image-small {
        height: 41px;
        margin: auto 0px;
    }
}

@media (min-width: 414px) and (max-width: 899px) {
    .footer-image-medium {
        height: 41px;
        margin: auto 0px;
    }
}

@media (min-width: 900px) {
    .footer-image-large {
        height: 60px;
        margin: auto 0px;
    }
}
