  .mobileLeftAlignStyle {
    font-family: JohnsonDisplay, Arial;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 60px;
    margin-left: 20px;
  }
  
  .topLine {
    border-top: 1px solid #d1cece;
    width: 90%;
    margin-bottom: 1em;
    margin-left: 1px;
  }
  
