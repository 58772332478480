.emotionSelect {
  display: flex;
  flex-direction: column;

  .question {
    font-family: JohnsonDisplay, Arial;
    font-size: 19px;
    line-height: 28.8px;
    text-align: left;
    margin-bottom: 16px;
    max-width: 300px;
    word-wrap: break-word;
    white-space: normal;
    align-self: flex-start;
  }

  .buttonsContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 400px;
  }
}
