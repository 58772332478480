@import 'src/variables.scss';

.LoadingIcon-container-loading {
  display: flex;
  justify-content: center;
  padding: 33% 0 33%;
}

.LoadingIcon-container-loading span {
  color: $grey;
}

.LoadingIcon-container-loaded {
  margin: auto;
}

