/* css properties based on UI deck
SectionHeader will stay as h1 element but adopts
the sizing of h1,h2,h3 elements to prevent rerendering elements*/

/* Large - h1 heading */
.section-header-size-large {
  font-weight: bold;
  font-size: 2.28rem;
  line-height: 2.67rem;
  padding-bottom: 0px;
}
/* Medium - h2 heading */
.section-header-size-medium {
  font-weight: bold;
  font-size: 1.45rem;
  line-height: 1.78rem;
  padding-bottom: 0px;
}
/* Small - h3 heading */
.section-header-size-small {
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1.39rem;
  padding-bottom: 0px;
}

.section-header-mobile {
    font-family: JohnsonDisplay, Arial;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 20px;
  }

