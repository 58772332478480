@import 'src/variables.scss';

.button-cta-red-oval button, .button-cta-yellow-oval button, .button-cta-red-line-oval button, .button-cta-grey-oval button, .button-filled-grey-oval button {
  text-transform: unset;
  font-size: 16px;
  font-family: 'JohnsonText', Arial;
  letter-spacing: 0px;
  text-align: center;
  line-height: 16px;
  font-weight: 600;
  border-radius: 50px;
  width: 120px;
  height: 40px;
  overflow: hidden;
  white-space: nowrap;
}

.button-cta-red-oval button{
  background-color: #EB1700 !important;
  color: white !important;
  border: 1px solid #EB1700 !important;
}

.button-cta-red-line-oval button{
  background-color: white;
  color: #212121;
  border: 1px solid $jnj-red;
}

.button-cta-grey-oval button {
  background-color: white;
  color: #212121;
  border: 1px solid $grey;
  margin-bottom: 10px;
}
.button-cta-grey-oval button:hover,  .button-cta-grey-oval button:focus{
  color: $jnj-red;
  border: 1px solid $jnj-red;
}

.button-filled-grey-oval button {
  background-color: #5E50471A;
  color: #212121;
  border: none;
  margin-bottom: 10px;
}

.button-cta-red-line-oval button:hover, .button-cta-red-line-oval button:focus{
  background-color: $jnj-red;
  color: white;
  border: 1px solid $jnj-red;
}
.button-cta-red-oval button:hover{
  background-color: #CC1400;
  color: white;
  border: 1px solid #CC1400;
}
.focused{
  display: inline-block;
  box-sizing: border-box;
  border: 2px solid #0F68B2;
  border-radius: 0px;
}

.button-cta-red-oval button:focus{
  background-color: #CC1400;
  color: white;
  border: 1px solid #CC1400;
}

.button-cta-red-oval button:active{
  background-color: #AD1100;
  color: white;
  border: 1px solid #AD1100;
  border-radius: 50px;
}

.button-cta-red-oval button:disabled, .button-cta-red-line-oval button:disabled, .button-cta-grey-oval button:disabled {
  background-color: #EB17000D;
  color: #5E504780;
  border: none;
}

.button-cta-red-line-oval-medium {
  @extend .button-cta-red-line-oval;

  button {
    font-size: 14px;
    border: 0.9px solid #312C2A;
    height: 38px;
    text-transform: unset;
    border-radius: 20px;
    width: 100%;
    height: 38px;
    bottom: 10px;
  }
}

.button-cta-red-oval-small {
  @extend .button-cta-red-oval;

  button {
    width: 90px;
    height: 28px;
    font-size: 12px;
    font-weight: 600;
    white-space: nowrap;
    border-radius: 50px;
    margin: 2px;
  }
}

.button-cta-red-oval-medium {
  @extend .button-cta-red-oval;

  button {
    padding: 5px 50px 5px 50px;
    border-radius: 24px;
    width: 275px;
    height: 40px;
  }
}

.button-cta-red-oval-edit-profile {
  @extend .button-cta-red-oval;

  button {
    padding: 5px 50px 5px 50px;
    border-radius: 24px;
    width: 150px;
    height: 40px;
  }
}

.button-cta-red-oval-edit-profile-mobile {
  @extend .button-cta-red-oval;

  button {
    padding: 5px 50px 5px 50px;
    border-radius: 24px;
    width: 350px;
    height: 44px;
    margin-bottom: 10px;
  }
}

.button-cta-grey-oval-edit-profile-mobile {
  @extend .button-cta-grey-oval;

  button {
    padding: 5px 50px 5px 50px;
    border-radius: 24px;
    width: 350px;
    height: 44px;
  }
}


.button-app-card-desktop {
  @extend .button-cta-red-oval;

  button {
    padding: 5px 50px 5px 50px;
    border-radius: 24px;
    width: 260px;
    height: 35px;
  }
}

.button-app-card-mobile {
  @extend .button-cta-red-oval;

  button {
    padding: 5px 50px 5px 50px;
    border-radius: 24px;
    width: 250px;
    height: 40px;
    margin: 0;
  }
}

.button-cta-grey-oval-small {
  @extend .button-cta-grey-oval;

  button {
    background-color: lightgray;
    border: none;
    width: 36px;
    height: 36px;
    font-size: 14px;
    white-space: nowrap;
    border-radius: 16px;
    margin: 0;
  }
}

.button-cta-grey-oval-large {
  @extend .button-cta-grey-oval;

  button {
    width: 150px;
  }
}

.button-cta-grey-oval-modal {
  @extend .button-cta-grey-oval-small;

  button {
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    font-size: 14px;
    border: 0.9px solid #312C2A;
    width: 100%;
    height: 38px;
    text-transform: unset;
    border-radius: 20px;
  }
  button:hover {
    background-color: #3D3734;
    color: white;
  }
}

.button-cta-grey-oval-snackbar {
  @extend .button-cta-grey-oval-small;

  button {
    background-color: #564C47;
    color: rgb(255, 253, 253);
    font-size: 14px;
    border: 0.9px solid #000000;
    width: 100%;
    height: 38px;
    text-transform: unset;
    border-radius: 20px;
  }
  button:hover {
    background-color: #3D3734;
    color: white;
  }
}

.button-cta-yellow-oval {
  @extend .button-cta-yellow-oval;

  button {
    min-width: 114px;
    font-size: 12px;
    height: 28px;
    background-color: #FDA037;
    color: black;
    border: none;
    text-align: center;
    margin-top: 20px;
  }
}

.button-cta-yellow-oval-mobile {
  @extend .button-cta-yellow-oval;

  button {
    min-width: 200px;
    font-size: 12px;
    height: 28px;
    background-color: #FDA037;
    color: black;
    border: none;
    text-align: center;
    margin-top: 20px;
  }
}

.button-cta-grey-oval-upload {
  @extend .button-cta-grey-oval;

  button {
    min-width: 146px;
    font-size: 12px;
    height: 28px;
    border: none;
    background-color: rgb(94, 80, 71, 0.10);
    color: black;
    text-align: center;
    margin-top: 20px;
    font-family: 'JohnsonText', Arial;
    font-weight: 'medium';
  }
}

.button-cta-grey-oval-upload button:hover {
  background-color: rgb(94, 80, 71, 0.20);
  color: black;
  border: none;
  font-family: 'JohnsonText', Arial;
  font-weight: 'medium';
}

.button-cta-yellow-oval button:hover,  .button-cta-yellow-oval button:focus{
  background-color: #F57535;
  color: black;
  border: none;
}

.icon-small {
  transform: scale(0.6);
  margin-right: -7px;
}

.icon-default {
  transform: scale(1);
}

.buttonLogo {
  vertical-align: middle;
}

