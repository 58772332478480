@import 'src/variables.scss';
@import 'src/App.scss';

.surveyFreeFormQuestion {
  margin: 20px 0;
  width: 100%;
  display: flex;
  flex-direction: column;

  .promptText {
    font-family: JohnsonText, Arial;
    font-size: 16px;
    margin-bottom: 10px;
    word-wrap: break-word;
    white-space: normal;
    max-width: 400px;
    width: 100%;
  }

  .textBox {
    width: 80%;
    height: 90px;
    max-height: 193px;
    padding: 15px;
    font-size: 1.1em;
    border: 1px solid #bbbaba;
    resize: vertical;
    box-sizing: border-box;
    font-family: JohnsonText, Arial;
    font-size: 15px;
    color: #333;

    &::placeholder {
      font-size: 15px;
      font-family: JohnsonText, Arial;
      color: #5E5047B2;
    }
  }
}
