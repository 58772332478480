@import "variables.scss";

html {
  font-size: 112.5%; /* 18px base font size */
}

body {
  color: $black;
  margin: 0;
  font-family: 'JohnsonText', Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
