@import '../../variables';

.reset-password-container {
  text-align: left;
  padding-left: 200px;
  margin-top: 50px;
}

.reset-password-header h1 {
    margin-top: 0px;
    font-family: JohnsonDisplay, Arial;
}

.reset-password-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.reset-password-buttons button {
    width: 230px;
    font-size: 14px;
    margin-left: 0px;
    margin-right: 20px;
}

.reset-password-message {
    margin-top: 5px;
    margin-bottom: 25px;
    text-align: left;
}

.reset-password-confirmation-container{
    width: 75vw;
}

.mobile-reset-password-container {
    text-align: left;
    margin-top: 5%;
    margin-left: 5px;
    padding-right: 5px;
    flex-direction: column;
    padding-left: 15px;
}

.mobile-reset-password-header h1 {
    font-size: 24px;
    margin-right: 120px;
    text-align: left;

}

.mobile-reset-password-message {
    margin-top: 5px;
    margin-bottom: 25px;
    text-align: left;
    font-size: 14px;
}

.mobile-reset-password-confirmation-container {
    width: 90vw;
}

.mobile-reset-password-buttons {
    display: grid;
}

.mobile-reset-password-buttons button {
    width: 320px;
    font-size: 14px;
    margin: 12px;
}
.mobile-section-header {
    text-align: left;
}

.mobile-home-text{
    font-family: JohnsonText, Arial;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    color: black;
    // margin-right: 10px;
}

.mobile-home-icon {
    margin-right: 2px;
    color: black;
}

.email-icon {
    color: white;
}

.JNJ-footer{
    padding-top: 350px;
}