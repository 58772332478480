@import "variables.scss";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@font-face {
  font-family: "J&J Circular";
  src: url('../public/fonts/J&J CircularTT-Light.woff2') format('woff2');
}
@font-face {
  font-family: "J&J Circular";
  src: url('../public/fonts/J&J CircularTT-Black.woff2') format('woff2');
  font-weight: bold;
}

@font-face {
  font-family: "JohnsonDisplay";
  src: url('../public/fonts/JohnsonDisplayRegular.woff');
}
@font-face {
  font-family: "JohnsonDisplay";
  src: url('../public/fonts/JohnsonDisplayLight.woff');
  font-weight: lighter;
}
@font-face {
  font-family: "JohnsonDisplay";
  src: url('../public/fonts/JohnsonDisplayBold.woff');
  font-weight: bold;
}

@font-face {
  font-family: "JohnsonText";
  src: url('../public/fonts/JohnsonTextRegular.woff');
}
@font-face {
  font-family: "JohnsonText";
  src: url('../public/fonts/JohnsonTextLight.woff');
  font-weight: lighter;
}
@font-face {
  font-family: "JohnsonText";
  src: url('../public/fonts/JohnsonTextBold.woff');
  font-weight: bold;
}
@font-face {
  font-family: "JohnsonText";
  src: url('../public/fonts/JohnsonTextRegular.woff');
  font-weight: 550;
}

@font-face {
  font-family: 'JohnsonTextMedium';
  src: url('../public/fonts/JohnsonText-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#okta-sign-in.auth-container.main-container {
  background: red;
  color: #fff;
  border: none;
  box-shadow: unset;
}

#okta-sign-in.auth-container .okta-sign-in-header,
#okta-sign-in.no-beacon .auth-content {
  border: none;
}

#okta-sign-in.auth-container h2 {
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 52px;
  color: #fff;
}

#okta-sign-in.auth-container .okta-form-label {
  display: flex;
  color: #fff;
}

#okta-sign-in.auth-container .button-primary,
#okta-sign-in.auth-container .button-primary:hover {
  background: #EB1700;
  height: auto;
  border-color: #fff;
}

#okta-sign-in.auth-container .link:visited {
  display: flex;
  color: #fff;
}

#okta-sign-in.auth-container .link {
  color:#fff;
}

#okta-sign-in.auth-container .link:link{
  color: #fff;
}
#okta-sign-in .siw-main-view .infobox-error,
#okta-sign-in .siw-main-view .infobox-warning {
  color: #6e6e78;
}

#okta-sign-in.auth-container .okta-form-subtitle {
  color: #fff;
}

#okta-sign-in .siw-main-view .siw-main-body span.strong {
  color: #fff;
}

#okta-sign-in .siw-main-view .siw-main-body .identifier-container {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  position: relative;
  top: -5px;
  margin-bottom: 15px;
  height: 27px;
  padding-top: 3px;
  border: 1px solid white;
  width: 51%;
  margin-left: 23%;
}

#okta-sign-in .auth-beacon {
  box-shadow: none;
}
#okta-sign-in .auth-beacon-border {
  border: none;
}
#okta-sign-in.auth-container h2,
#okta-sign-in.auth-container h3 {
  color: #fff;
}
#okta-sign-in.auth-container h2 {
  font-family: "JohnsonDisplay";
  font-weight: 700;
}


#okta-sign-in.auth-container .button:focus{
  outline: none;
  box-shadow: none;
  background: #000099!important;
  border-color: #b2b2b2 #b2b2b2 #afafaf;
}



#okta-sign-in .siw-main-view .siw-main-body .identifier-container:before {
  //content: url(assets/user.png);
  margin-right: 10px;
}
#okta-sign-in .siw-main-view .siw-main-body .identifier {
  font-size: 15px;
}

.MuiAppBar-root.MuiAppBar-colorPrimary.MuiAppBar-positionAbsolute {
  box-shadow: none;
}
