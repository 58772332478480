@import 'src/variables.scss';

.hidden {
  display: none;
}

.open {
  width: 100%;
  background-color: black;
  padding-bottom: 170%;
  position: absolute;
  left: 0;
}

.header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 3%;
}

.logo {
  height: 22px;
  margin-left: 3%;
  filter: brightness(0) invert(1);
}

.closeIcon {
  color: white;
}

.profile {
  display: flex;
  align-items: center;
  padding-top: 10%;
  padding-bottom: 5%;
  margin-left: 22px;
}

.userInfo {
  text-align: left;
  padding-left: 10px;
}

.userName {
  margin-left: 0.5rem;
  margin-right: 1rem;
  color: white;
  font-weight: 600;
  font-size: 14px;
}

.loginDate {
  margin-left: 0.5rem;
  margin-right: 1rem;
  color: white;
  font-weight: 400;
  font-size: 14px;
  display: inline-block;
}

.menuItem {
  padding-bottom: 3%;
}

.menuItemLink {
  width: 100%;
  text-decoration: none;
}

.menuItemText {
  font-family: "JohnsonText", Arial;
  font-size: 16px;
  font-weight: 600;
  color: white;
}

.sectionbreak {
  width: 90%;
}

.logoutIcon {
  color: white;
}