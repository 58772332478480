@import 'src/App.scss';

.appCardDesktop {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border: 1px solid $light-grey;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
  font-family: 'JohnsonTextMedium', Arial;
  text-align: center;
  transition: box-shadow 0.3s;
  padding: 22px;
  width: 390px;
}

.appCardDesktop:hover {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.appCardMobile {
  position: relative;
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid $light-grey;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
  font-family: 'JohnsonTextMedium', Arial;
  text-align: center;
  transition: box-shadow 0.3s;
  padding: 16px;
  margin-top: 3%;
}

.appCardMobile:hover {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.appLogoContainer {
  height: 100px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $light-grey;
  border-radius: 5px;
  padding: 10px;
}

.appLogoContainerMobile {
  height: 80px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $light-grey;
  border-radius: 5px;
  padding: 10px;
}

.appLogo {
  max-width: 100%;
  max-height: 100%;
}

.appLogoMobile {
  max-width: 100%;
  max-height: 100%;
}

.appInitial {
  width: 100%;
  height: 150px;
  background-color: $light-grey;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  color: $grey;
}

.appInitialMobile {
  width: 100%;
  height: 100px;
  background-color: $light-grey;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: $grey;
}

.favoriteContainer {
  position: absolute;
  top: 0;
  right: 0;
  padding: 13px;
}

.favoriteContainerMobile {
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px;
}

.favoriteIcon {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 20px;
  margin-top: 12px;
  color: $grey;
  & .MuiSvgIcon-root {
    font-size: 1.5rem;
  }
}

.favoriteIconMobile {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 12px;
  margin-top: 6px;
  color: $grey;
  & .MuiSvgIcon-root {
    font-size: 1.2rem;
  }
}

.favoriteText {
  font-family: 'JohnsonTextMedium', Arial;
  font-size: 16px;
  color: $dark-grey;
  margin-right: -3px;
}

.favoriteTextMobile {
  font-family: 'JohnsonTextMedium', Arial;
  font-size: 14px;
  color: $dark-grey;
  margin-right: -3px;
}

.openButton {
  align-self: flex-end;
  margin-top: auto;
}

.cardContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  margin-left: 20px;
}

.cardContentMobile {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  text-align: left;
  margin-left: 20px;
}

.appName {
  font-size: 24px;
  font-family: 'JohnsonDisplay', Arial;
  font-weight: 1000;
  color: black;
  align-self: flex-start;
  margin-bottom: 80px;
  margin-top: 20px;
}

.appNameMobile {
  font-size: 20px;
  font-family: 'JohnsonDisplay', Arial;
  font-weight: 1000;
  color: black;
  align-self: left;
  margin-bottom: 24px;
}

.actionLinks {
  width: 100px;
  position: absolute;
  bottom: 9px;
  right: 168px;
  padding: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.actionLinksMobile {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.manageLink {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  font-family: 'JohnsonTextMedium', Arial;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: black;
  text-decoration: underline;
  margin-right: 73px;
}

.manageLinkMobile {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  font-family: 'JohnsonTextMedium', Arial;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: black;
  text-decoration: underline;
  margin-right: 16px;
}