.dialogContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  overflow: hidden;
  padding: 20px;
}

.dialogActions {
  justify-content: center;
  display: flex;
  width: 100%;
}

.dialogContent {
  overflow-y: hidden;
  display: flex;
  height: 600px;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 8px;
}

.uploadText {
  margin-right: 200px;
  font-family: JohnsonDisplay, Arial;
  font-size: 26px;
  font-weight: 700;
  line-height: 33.54px;
  text-align: left;
}

.fileUploadBox {
  display: flex;
  justify-content: center;
  height: 150px;
  margin-bottom: 0;
}

.buttonContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 15px;
}

.imageDisplayContainer {
  width: 100%;
  display: flex;
  justify-content: left;
}

.alert {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 100px;
}

.alertContainer {
  position: relative;
  width: 100%;
  max-width: 430px;
  margin-bottom: 20px;
}

.fileUploadBoxError {
  position: relative;
  display: flex;
}

.dialogActionsLeft .buttonContainer {
  margin-top: 25px;
  justify-content: flex-start;
  display: flex;
}

.saveButton {
  padding-right: 40px;
  padding-left: 10px;
}

.closeButton {
  position: absolute;
  right: 5px;
  top: 5px;
  display: flex;
  align-items: center;
}

.alertCloseButton {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 30px;
  width: 35px;
  display: flex;
  align-items: center;
}

.closeText {
  font-size: 16px;
  position: absolute;
  right: 40px;
  display: flex;
  align-items: center;
}

.dialogContainerMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
  overflow: hidden;
}

.dialogContentMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.uploadTextMobile {
  font-family: JohnsonDisplay, Arial;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
  margin-bottom: 50px;
  max-width: 250px;
  margin-right: 30px;
}

.fileUploadBoxMobile {
  display: flex;
  justify-content: center;
  height: 150px;
  margin-bottom: 20px;
  border: none;
}

.fileUploadBoxErrorMobile {
  position: relative;
  display: flex;
  justify-content: center;
  height: 150px;
  margin-bottom: 20px;
}

.dialogActionsMobile {
  justify-content: center;
  display: flex;
  width: 100%;
  margin-top: 20px;
}

.buttonContainerMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 300px;
}

.imageDisplayContainerMobile {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.alertContainerMobile {
  position: relative;
  width: 100%;
  max-width: 350px;
  margin-bottom: 20px;
}

.closeButtonWrapperMobile {
  position: absolute;
  right: 30px;
  top: 10px;
  z-index: 20; /* Ensure the close button is on top */
}

.closeButtonMobile {
  display: flex;
  align-items: center;
}

.closeIconMobile {
  color: black;
}

.closeTextMobile {
  font-size: 15px;
  position: absolute;
  right: 30px;
  top: -7px;
  display: flex;
  align-items: center;
  font-weight: 1000;
}

.saveButtonMobile button{
  width: 290px;
  margin-right: 15px;
}

.cancelButtonMobile button{
  width: 290px;
  background-color: rgb(239, 238, 237);
  margin-right: 15px;
  border: none;
}