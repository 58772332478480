.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.drag-drop-box {
  width: 419px;
  height: 134px;
  border: 2px dashed #544E4E;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #F9F8F7;
  cursor: pointer;
}

.drag-drop-box input {
  display: none;
}

.drag-drop-box-text {
  margin: 0;
  width: 341px;
  height: 38px;
  top: 850px;
  left: 2654px;
  line-height: 20.64px;
  font-family: sans-serif;
  font-size: 16px;
  text-align: center;
  padding-bottom: 2px;
}

.drag-drop-box p {
  font-weight: 500;
  color: #000000;
}

.drag-drop-box span {
  font-weight: 700;
  text-decoration: underline;
  color: #EB1700;
  cursor: pointer;
}

.drag-drop-box ul {
  list-style-type: none;
  padding: 0;
}

.drag-drop-box li {
  margin-top: 5px;
}

.icon {
  width: 38.33px;
  height: 30.26px;
  top: 7.67px;
  left: 3.83px;
}

.withprompt {
  padding-top: 14px;
  vertical-align: middle;
}

.drag-drop-box-mobile {
  width: 240px;
  height: 150px;
  border: 2px dashed #544E4E;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #F9F8F7;
  cursor: pointer;
  padding: 20px;
}

.drag-drop-box-text-mobile {
  margin: 0;
  width: 100%;
  line-height: 20.64px;
  font-family: sans-serif;
  font-size: 16px;
  text-align: center;
  padding-bottom: 2px;
}

.icon-mobile {
  width: 30px;
  height: 24px;
  top: 6px;
  left: 3px;
}

.withprompt-mobile {
  padding-top: 10px;
  vertical-align: middle;
}

.redUnderlined {
  color: red;
  text-decoration: underline red;
  font-weight: 600;
}

.boldFont {
  font-weight: 600;
}
