@import '../../variables';

.edit-account-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  font-family: 'JohnsonText', Arial, sans-serif;
}

.profile-picture-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.profile-picture-wrapper {
  margin-bottom: 10px;
}

.profile-picture-support-text {
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  max-width: 250px;
}

@media (max-width: 768px) {
  .edit-account-container {
    margin-top: 30px;
    max-width: 300px;
  }

  .edit-account-form {
    .form-field {
      margin-bottom: 20px;
    }

    .fields-header {
      margin-bottom: 10px;
    }

    .form-label {
      font-family: 'JohnsonDisplay', Arial, sans-serif;
      margin-bottom: 5px;
      line-height: 1.4;
      max-width: 300px;
      min-width: 300px;
    }

    .form-secondary-text {
      max-width: 300px;
      min-width: 300px;
    }

    .buttons {
      flex-direction: column;
      align-items: center;
      width: 100%;

      > div {
        margin-bottom: 10px;
        width: 100%;
      }
    }
  }
}