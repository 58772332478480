@import '../../../variables';

.signout-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loading-icon {
    display: flex;
    justify-content: center;
    margin-top: 7rem;
    margin-bottom: 0.5rem;
  }

  .loading-icon span {
    color: $light-grey;
  }

.jnj-footer {
    margin-top: 50.7vh;
}