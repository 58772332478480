@import '../../App';

.parent-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-left: 5px;
}

.my-account-container {
  text-align: left;
  line-height: 35px;
  font-family: 'JohnsonText', Arial, sans-serif;

  > *:not(:first-child):not(:last-child) {
    border-bottom: 1px solid #ccc;
    margin-top: 40px;
    margin-bottom: 40px;
    font-family: "JohnsonText", Arial;
  }
}

.account-header {
  text-align: left;
  font-family: 'JohnsonDisplay', Arial;
}

.profile-picture-container {
  border-bottom: 1px solid #ccc;
  padding-bottom: 40px;
}

.manage-button button {
  max-width: 100%;
  max-height: 100%;
  width: 200px;
  font-size: 14px;
  padding: 0.2rem;
  margin-top: 0.75rem;
  margin-bottom: 1.5rem;
}

.profile-picture-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 50px;
}

.profile-picture-wrapper {
  flex-shrink: 0;
  max-width: 250px;
}

.profile-picture-support-text {
  margin-left: 20px;
  font-size: 13px;
  line-height: 18.06px;
  max-width: 19rem;
  font-family: "JohnsonText", Arial;
}

.alert-container {
  width: 100%;
  margin: auto
}

.data-row {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}

.data-label {
  font-weight: bold;
  margin-right: 5px;
  font-family: "JohnsonText", Arial;
}

.data-value {
  flex: 1;
  font-family: "JohnsonText", Arial;
}

.mobile-my-account-container {
  text-align: left;
  line-height: 25px;
  margin-left: 20px;
  padding-right: 20px;
  font-family: "JohnsonText", Arial;
}

.mobile-user-profile-info-container {
  border-top: 1px solid #ccc;
  margin-bottom: 30px;
  text-align: left;
}

.mobile-pref-lang-container {
  border-top: 1px solid #ccc;
  text-align: left;
  font-family: "JohnsonText", Arial;
}

.mobile-manage-button button {
  max-width: 100%;
  max-height: 100%;
  width: 200px;
  font-size: 14px;
  padding: 0.2rem;
  margin-left: 0rem;
  margin-top: 1rem;
  background-color: rgb(239, 238, 237);
}

.mobile-upload-button button {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  font-size: 12px;
  padding: 0.2rem;
  height: 30px;
  margin-left: 0rem;
  margin-top: 1rem;
  background-color: rgb(239, 238, 237);
  border: none;
}

.mobile-delete-button button {
  width: 250px;
}

.mobile-edit-language-button button {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  font-size: 14px;
  padding: 0.2rem;
  margin-left: 0rem;
  margin-top: 1rem;
  background-color: rgb(239, 238, 237);
  border: none;
}

.mobile-spacing {
  padding-bottom: 20px;
}

.mobile-profile-picture-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.mobile-profile-picture-wrapper {
  flex-shrink: 0;
  max-width: 250px;
}

.mobile-profile-picture-support-text {
  margin-left: 20px;
  font-size: 13px;
  line-height: 18.06px;
  max-width: 19rem;
  font-family: "JohnsonText", Arial;
}

.mobile-section-header-left-align {
  font-family: 'JohnsonDisplay', Arial;
  font-weight: bold;
  font-size: 24px;
  line-height: 1.39rem;
  margin-bottom: 20px;
  justify-content: flex-start;
  text-align: left;
  margin-top: 40px;
}

.mobile-section-subheader-left-align {
  font-family: 'JohnsonText', Arial;
  font-weight: bold;
  font-size: 24px;
  line-height: 1.39rem;
  margin-bottom: 20px;
  justify-content: flex-start;
  text-align: left;
  margin-top: 40px;
}

.mobile-manage-button button {
  width: 100%;
  border: none;
}

.mobile-password-container {
  border-top: 1px solid #ccc;
  text-align: left;
}

.mobile-data-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.mobile-data-label {
  font-weight: bold;
  margin-bottom: 5px;
  margin-right: 5px;
  font-family: "JohnsonText", Arial;
}

.mobile-data-value {
  margin-bottom: 10px;
  font-family: "JohnsonText", Arial;
}