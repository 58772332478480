$primary-color: #EB1700;

// Primary Colors from UI Deck
$ex-light: #F4F4F4;
$light-grey: #D8D8D8;
$grey: #888B8D;
$dark-grey: #63666A;
$black: #212121;
$jnj-blue: #000099;
$jnj-red: #EB1700;

// Interaction Colors
$success-grn: #0E9B19;
$active-field: #4545E0;
$lightest-red: #eb180020; // hover/selected color for side navigation buttons