@import 'src/App.scss';

.iconButton {
  width: 122px;
  height: 117px;
  border-radius: 10px;
  background-color: white;
  border: 2px solid lightgray;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 10px;

  svg {
    margin-bottom: 8px;
    flex-shrink: 0;
  }
}

.icon {
  transition: fill 0.3s ease;
  fill: currentColor;
}

.label {
  font-family: "JohnsonText", Arial;
  font-size: 14px;
  text-align: center;
  padding-top: 8px;
  font-weight: 550;
  padding-top: 8px;
  display: flex;
  align-items: center;
  color: black;
}
