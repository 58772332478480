@import 'src/variables.scss';

.application-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill,512px);
  gap: 1rem 0;
  justify-items: left;

  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 1405px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 939px) {
    grid-template-columns: 1fr;
  }
}

.application-logo-container {
    border: 1px solid $light-grey;
    background-color: $ex-light;
    border-radius: 30px;
    position: relative;
    padding-top: 100%;
}

.application-logo-link {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-decoration: none;
}

.application-logo-image {
    color: $black;
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
}

.application-logo-label {
    position: absolute;
    top: 110%;
    font-size: 0.9rem;
    font-weight: bold;
    color: $black;
    text-align: center;
}

.no-applications-message {
    font-weight: bold;
    color: $black;
    text-align: left;
    font-size: 1.47rem;
}

.error-contact-message {
    margin-top: 3%;
    font-weight: 550;
    color: $dark-grey;
    text-align: left;
    font-size: 0.98rem;
}

.mobile-application-grid {
  grid-template-columns: repeat(auto-fill, minmax(calc(50% - 2rem), 1px));
}

.mobile-application-logo-container {
  border: 1px solid $light-grey;
  background-color: $ex-light;
  border-radius: 30px;
  position: relative;
  padding-top: 100%;
}

.mobile-application-logo-link {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-decoration: none;
}

.mobile-application-logo-image {
  color: $black;
  max-width: 40%;
  max-height: 40%;
  object-fit: contain;
}

.mobile-application-logo-label {
  position: absolute;
  top: 110%;
  font-size: 0.65rem;
  font-weight: bold;
  color: $black;
  text-align: center;
}

.mobile-no-applications-message {
  font-weight: bold;
  color: $black;
  text-align: center;
  font-size: 1.18rem;
}

.mobile-error-contact-message {
  margin-top: 2%;
  font-weight: 550;
  color: $dark-grey;
  text-align: center;
  font-size: 0.79rem;
}


