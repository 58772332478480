@import '../../../variables';

.center {
  display: flex;
  align-items: center;
  flex-direction: column!important;
}

.login-content {
  flex-wrap: center;
  padding-top: 140px;
  max-width: 780px;
  margin: 1rem auto;
  text-align: left;
  margin-left: 10rem;
  margin-right: 10rem
}

.mobile-login-content {
  max-width: 465px;
  margin: 2rem auto;
  text-align: initial;
  padding-left: 1.5rem;
  padding-right: 1rem;
}

.login-title {
  font-family: 'JohnsonDisplay', Arial;
  font-style: normal;
  font-weight: bolder;
  font-size: 55px;
  line-height: 64px;
  color: #000000;
}

.mobile-login-title {
  font-family: 'JohnsonDisplay', Arial;
  max-width: 100%;
  font-weight: bold;
  font-size: 40px;
  line-height: 50px;
  color: #000000;
  padding-top: 1%;
}

.login-app-bar-header {
  font-weight: bolder;
  text-align: left;
  top: 40%;
  color: #FFF;
  padding-top: 2.5rem;
  padding-left: 10rem;
}

.mobile-login-app-bar-header {
  font-size: larger;
  position: absolute;
  top: 28%;
  padding-top: -3rem;
  padding-left: 2.5rem;
  color: #FFF;
}

.login-sub-title {
  max-width: 500px;
  margin-top: 20px;
  margin-bottom: 40px;
  margin-right: 1rem;
  font-family: 'JohnsonText', Arial;
  font-weight: 100;
  font-size: 15px;
  line-height: 22px;
}

.login-footer-blurb {
  max-width: 550px;
  margin-top: 70px;
  font-family: 'JohnsonText', Arial;
  font-weight: 100;
  font-size: 15px;
  line-height: 22px;
  color: #000000;
}

.mobile-login-footer-blurb {
  margin-top: 90px;
  font-family: 'JohnsonText', Arial;
  font-weight: 100;
  font-size: 15px;
  line-height: 22px;
  color: #000000;
}

.mobile-login-sub-title {
  margin-top: 20px;
  font-family: 'JohnsonText', Arial;
  font-weight: 100;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.login-okta {
  background: #EB1700;
  display: flex;
  align-items: center;
  justify-content: center;
}

.full-height {
  height: 100%
}

.full-viewport-height {
  height: 100vh
}

.container {
  height: 100%;
  padding: 0;
}

.mobile-full-viewport-height {
  background: #000099;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 0;
}

.mobile-container {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
}

//Exact mockup dimensions
.login-title-white {
  color: #FFF;
  text-align: center;
  font-family: "JohnsonText", Arial;
  font-size: 2.5625rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.jj-logo-mobile {
  width: 10.125rem;
  height: 1.875rem;
  margin-left: 14%;
}

.login-button{
  margin-top: -20px;
  margin-left: -10px;
}

.login-button-mobile {
  margin-top: 20px;
  margin-left: -2%;
}

.JNJ-shorthand-logo {
  opacity: 10%;
  width: 120%;
  height: auto;
}