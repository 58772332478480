@import '../../App';

.languageSelectorLabel {
  font-family: 'JohnsonText', Arial;
  margin-bottom: 5px;
  display: block;
  color: #000;
  font-size: 16px;
  font-weight: 1000;
}

.buttonGroup {
  display: flex;
  justify-content: space-between;
  width: 32.5%;
  margin-top: 30px;
  margin-bottom: 30px;
}

.buttonGroupMobile {
  margin-top: 19px;
  margin-bottom: 30px;
}
