@import '../../variables';
@import '../../App';

.container {
    position: relative;
    display: inline-block;
    width: 7rem;
    height: 7rem;
}

.img {
    display: grid;
    place-items: center;
    text-align: center;
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    color: #000000;
    background-color: #CFC8C2;
    overflow: hidden;
    font-size: 36px;
    line-height: 100%;
    object-fit: cover;
    font-family: 'JohnsonTextMedium', Arial, 'sans-serif';
}

.smallImage {
    display: grid;
    place-items: center;
    width: 37px;
    height: 37px;
    border-radius: 50%;
    color: black;
    background-color: lightgray;
    font-family: 'JohnsonTextMedium', Arial;
    font-size: 14px;
    object-fit: cover;
}

.smallImageContainer {
    position: relative;
    display: inline-block;
}

.add-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: #fff;
    color: #007aff;
    border: 0.1rem solid #007aff;
    cursor: pointer;
    transition: all 0.3s ease;
}
.add-icon:hover {
    background-color: #0062cc;
}