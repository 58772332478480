@import 'src/variables.scss';

.user-info {
  margin: auto;
  padding-left: 250px;
  text-align: left;
}

.user-info-p {
  text-align: left;
}

.mobile-user-info {
    text-align: center;
    margin-bottom: -80px;
  }

  .mobile-user-info-p {
    text-align: center;
  }